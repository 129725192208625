<template>
  <v-app>
    <!-- <v-app-bar app color="blue-dark" dark>
      <v-toolbar-title> -->
    <span class="font-weight-bold d-flex justify-content-center pt-10">
      <img
        :src="require('@/assets/images/myFFME.png')"
        style="max-width:100px"
      />
    </span>
    <!-- </v-toolbar-title>
    </v-app-bar> -->
    <div>
      <v-container style="padding-bottom: 100px;">
        <router-view></router-view>
      </v-container>
    </div>
  </v-app>
</template>

<script>
export default {
  props: {
    source: String,
  },

  created() {},
};
</script>
